import { LocationProvider } from '@reach/router'
import { IntlProvider } from 'gatsby-plugin-react-intl'
import React from 'react'

export const WrapWithProvider = ({ element, props }: any) => {
  const locale = element?.props?.pageContext?.language ?? 'en'
  const intl = element?.props?.pageContext?.intl ?? {}
  return (
    <IntlProvider {...intl} locale={locale}>
      <LocationProvider {...(props ? props : {})}>{element}</LocationProvider>
    </IntlProvider>
  )
}

export default WrapWithProvider
